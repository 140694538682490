import React, { useEffect } from "react";
import "./App.css";
import { HashRouter as Router, BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Navbar from "./commons/Navbar/index";
import Aos from "aos";
import { Home, ProjectDetails } from "./pages/index";
function App() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-out",
    });
  }, []);
  return (
    <>
      <BrowserRouter>
        <Navbar></Navbar>

        <Routes>
       
        <Route path={process.env.PUBLIC_URL} element={<Home />} />
          <Route
            path={"/Projects/:name"}
            element={<ProjectDetails />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
window.addEventListener('load', Aos.refresh)

