import React,{useState} from "react";
import image from "../../assets/imgs/Projects/Hinemoa/1.jpeg";
import { projects } from "../../dataprovider/data";
import { Link, useParams } from "react-router-dom";
import "./index.css";
export default function Index() {
  const { name } = useParams();
  console.log(name)
  const project = projects.find((p) => p.name === name);
  const [activeProject, setActiveProject] = useState(null);

  const handleProjectClick = (project) => {
    setActiveProject(project);
  };
  return (
    <>
    
      <section className="projects" data-aos="zoom-in-right">
        {/* =====================Start Breadcrubms=================== */}
      <div className="grid grid-row-2 justify-items-center items-center py-5">
<div className="inline-flex rounded-md shadow-sm " role="group">
  {projects.map((project) =>


    
    <Link to={`/Projects/${project.name}`} type="button" onClick={() => handleProjectClick(project)} className={`px-4 py-2 text-sm font-medium border ${
      activeProject === project ? 'bg-gray-900 text-white' : 'bg-transparent text-gray-900'
    } border-gray-900 hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700`}>
      {project.name}
      

    </Link>
)}

</div>
</div>


         {/* =====================End Breadcrubms================ */}
        <div className="">
          <div className="text-center md:text-2xl text-xl tracking-wide uppercase p-2 mt-5">
            {`${name} Project Gallery`} <br></br>{" "}
            <span className="decor"></span>
          </div>

          <div className="items-center px-5 " data-aos="fade-right" >
            {project.images.map(
              (image) =>
                image.url && (
                  <img
                    data-aos="fade-left"
                    style={{
                      display: "block",
                      margin: "0 auto",
                      textAlign: "center",
                      marginTop: "2rem",
                      borderRadius:"20px",
                     
                      height: "100vh",
                      
    maxWidth: "100%",
 
   
                      
                    }}
                    className="object-contain mt-3 "
                    key={image.id}
                    src={image.url}
                    alt={image.altText}
                  />
                )
            )}
          </div>
        </div>
        <br></br>
        <hr></hr>
      </section>
      <footer className="bg-neutral-200 text-center dark:bg-neutral-700 lg:text-left">
        <div className="p-2 text-center text-neutral-700 dark:text-neutral-200">
          © 2023 Copyright |
          <a
            className="text-neutral-800 dark:text-neutral-400 p-2"
            href="https://arkhomes.com.au/"
          >
            arkhomes | ALL Rights Reserved{" "}
          </a>
        </div>
      </footer>
    </>
  );
}
