import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import{ projects } from "../../dataprovider/data";
import Aos from "aos";
import "aos/dist/aos.css";
export default function Header() {
  let blue = "#0C2D48";
  const [bgColor, setBgColor] = useState(blue);
  const [toggleList,setToggleList]=useState(false);
  let targetDiv = "";
  const handleopenprojects = () => {
   
  setToggleList(!toggleList);
  
  };
  const handleThemeColor = (color) => {
    setBgColor(color);
  };
  
 
  useEffect(() => {
  
    window.addEventListener("keydown", handleKeyDown);

  
    // Remove event listener when the component unmounts
   
  
    targetDiv = document.getElementById("hidden");
    let img = document.getElementsByTagName("img");
    let text = document.getElementById("project-name");
    
    for (let i = 0; i < img.length; i++) {
      img[i].addEventListener("mouseover", function () {
        let alt = this.alt;
        text.textContent = alt;
      });
      img[i].addEventListener("mouseleave", function () {
        let alt = this.alt;
        text.textContent = "";
      });
    }
    filterSelection("all");
   
  });
  // Add active class to the current button (highlight it)

 
  const filterSelection = (c) => {
    let x, i;
    x = document.getElementsByClassName("column");
   
    if (c == "all") c = "";
    for (i = 0; i < x.length; i++) {
  
      w3RemoveClass(x[i], "show");
      //check if class is present
      if (x[i].className.indexOf(c) > -1) w3AddClass(x[i], "show");
    }
  };
  const w3AddClass = (element, name) => {
   
    let i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
 
    for (i = 0; i < arr2.length; i++) {
      if (arr1.indexOf(arr2[i]) == -1) {
        element.className += " " + arr2[i];
      }
    }
  };
  const w3RemoveClass = (element, name) => {
    let i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    
    for (i = 0; i < arr2.length; i++) {
      while (arr1.indexOf(arr2[i]) > -1) {
        arr1.splice(arr1.indexOf(arr2[i]), 1);
      }
    }
    element.className = arr1.join(" ");
  };


  const handleKeyDown = (e) => {
  
    if (e.key === "ArrowRight") {
     
     
      const sliderContainer = document.querySelector(".overflow-x-scroll");
     
      sliderContainer.scrollLeft += 20; 
    }
    if (e.key === "ArrowLeft") {

     
      const sliderContainer = document.querySelector(".overflow-x-scroll");
      // Scroll the slider by a certain amount
      sliderContainer.scrollLeft -= 20; // change 50 to your desired amount
    }
  };

  return (
    <>
      <section
        className=" p-6 min-h-screen "
        style={{ backgroundColor: bgColor }}
      >
        <div className="md:grid md:grid-cols-2">
         
            <div className="flex flex-col relative p-5 align-middle	">
           
       
       <div className="md:flex hidden scroll  rounded-lg bg-gray-700  w-40 px-3 py-1">
        <h2 className="text-white">Scroll to left</h2>
       <span className="animate_it mt-2 p-1 ml-2">
      
      </span>
       </div>
            </div>
            
       <div>
        
       

        <div className="mt-2" data-aos="zoom-in">
        
          <div className="flex flex-col m-auto ">
            <div className=" flex overflow-x-scroll pb-10 hide-scroll-bar">
              <div className=" flex flex-nowrap  ">
                <div className="slider active md:ml-12">
                  <div className="slide flex  w-100 md:ml-12 ">
                    {projects.map((project) =>
                     
                        
                          <div key={project.id}
                            className={` project column ${project.name}   w-40  px-3 `} data-aos="zoom-in"
                          >
                            <Link  to={`/Projects/${project.name}`} state={{ activeProject: project }}>
                              <img
                                className="max-w-none cursor-pointer "
                                 style={{overflowX:"scroll !important"}}
                                
                                key={project.id}
                                src={project.mainImg}
                                alt={project.altText}
                              />
                            </Link>
                          
                        </div>
                     
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center w-50 h-0.5 ">
          <div id="project-name" className=" font-medium text-white"></div>
        </div>
        
        </div></div>
         <div  className="backgroundSelector bg-white py-1 px-2 ml-4 ">
            <div
              className="dot terracotta"
              onClick={() => handleThemeColor("#1A4314")} style={{backgroundColor:"#1A4314"}}
            ></div>
            <div
              className="dot cement bg-orange-300"
              onClick={() => handleThemeColor("#0C2D48")}  style={{backgroundColor:"#0C2D48"}}
            ></div>
            <div
              className="dot black bg-gray-800"
              onClick={() => handleThemeColor("#000000d9")}
            ></div>
          </div>
        
       {/* <div className="text-center ml-4 md:w-64 md:flex w-40 mt-8   animate__animated animate__fadeInRight ">
          
          <button
            className="font-medium text-white uppercase"
            onClick={handleopenprojects}
          >
           Project Explorer
          </button>
          {toggleList &&
          <div className="filter__projects text-center w-10  animate__animated animate__fadeInRight">
            <div className="filterlist " >
              <div id="myBtnContainer">
                <ul className="text-white font-light px-2 text-center md:flex ">
                  <li className="md:p-2">
                    <button
                      className="btn"
                      onClick={() => filterSelection("all")}
                    >
                      <span className="filterlink ">
                        All<div className="underline"></div>
                      </span>
                    </button>
                  </li>
                  <li className="md:p-2">
                    <button
                      className="btn"
                      onClick={() => filterSelection("Hinemoa")}
                    >
                      <span className="filterlink ">
                        Hinemoa<div className="underline"></div>
                      </span>
                    </button>
                  </li>
                  <li className="md:p-2">
                    <button
                      className="btn"
                      onClick={() => filterSelection("Dove")}
                    >
                      <span className="filterlink ">
                        Dove<div className="underline"></div>
                      </span>
                    </button>
                  </li>
                  <li className="md:p-2">
                    <button
                      className="btn"
                      onClick={() => filterSelection("Thomas")}
                    >
                      <span className="filterlink ">
                        Thomas<div className="underline"></div>
                      </span>
                    </button>
                  </li>
                  <li className="md:p-2">
                    <button
                      className="btn"
                      onClick={() => filterSelection("Ely")}
                    >
                      <span className="filterlink ">
                        Ely<div className="underline"></div>
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>}
         
          </div>*/}
      </section>
    </>
  );
}
