import React, { useState, useEffect } from "react";
import logo from "../../assets/imgs/logo/logo.png";
import "./index.css";
import { Link } from "react-router-dom";
export default function Index() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const handleMenu = () => {
    setToggleMenu(!toggleMenu);
  };
  return (
    <>
      <nav 
        className="flex-no-wrap relative flex w-full items-center justify-between bg-neutral-100 py-4 shadow-md  lg:flex-wrap lg:justify-start"
        data-te-navbar-ref
      >
        <div className="flex w-full flex-wrap items-center justify-between px-6">
          <div className="">
            <Link
              className="mt-2 mr-2 flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:text-neutral-400 lg:mt-0"
              to={process.env.PUBLIC_URL}
              onClick={(e) => {
                this.handleEntailmentRequest(e);
              }}
            >
              <img
                src={logo}
                style={{ height: "40px", width: "100px" }}
                alt=""
                loading="lazy"
              />
            </Link>
          </div>

          <div className="relative flex items-center">
            <button
              className="mr-4 text-neutral-500 hover:text-neutral-700 focus:text-neutral-700 disabled:text-black/30 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300 [&.active]:text-black/90 dark:[&.active]:text-neutral-400"
              onClick={handleMenu}
            >
              <span className="[&>svg]:w-5">
                <span className="material-icons md-18">menu</span>
              </span>
            </button>
          </div>
        </div>
      </nav>
      {
        //open menu for large screens on toggle
        toggleMenu && (
          <div className="wrapper h-100 w-100 bg-gray-800 text-white" >
            <div className="hidden-nav animate__animated animate__fadeInLeft bg-gray-100">
              <div className="h-100 w-100 d-flex flex-column justify-content-center position-relative">
                <div className="menu animate__animated animate__fadeInUp">
                  <div className="upper_section_nav">
                    <nav 
                      className="flex-no-wrap relative flex w-full items-center justify-between  py-4 shadow-md shadow-black/5 dark:bg-neutral-600 dark:shadow-black/10 lg:flex-wrap lg:justify-start"
                      data-te-navbar-ref
                    >
                      <div className="flex w-full flex-wrap items-center justify-between">
                        <div className=" " data-aos="flip-left">
                          <Link
                            className="mt-2 mr-2 flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:text-neutral-400 lg:mt-0"
                            to={process.env.PUBLIC_URL}
                            onClick={(e) => {
                              this.handleEntailmentRequest(e);
                            }}
                          >
                            <img
                              src={logo}
                              style={{ height: "40px", width: "100px" }}
                              alt=""
                              loading="lazy"
                            />
                          </Link>
                        </div>

                        <div className="relative flex items-center">
                          <button
                            className="mr-4 text-neutral-500 hover:text-neutral-700 focus:text-neutral-700 disabled:text-black/30 dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300 [&.active]:text-black/90 dark:[&.active]:text-neutral-400"
                            onClick={handleMenu}
                          >
                            <span className="[&>svg]:w-5">
                              <span className="material-icons md-18" >
                                close
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </nav>
                  </div>
                  <div className="md:grid md:grid-cols-2 justify-center p-10 relative">
                    <div className="hidden md:block p-5">
                      <h3 className="font-semibold  text-4xl">ABOUT US</h3>
                      <p className="py-4 text-justify">
                        We at ARK Homes not only offers quality to our clients
                        but make sure that we will be there for them throughout
                        the project. We start by providing consultation on
                        selecting the best site for the project choosing the
                        best designs and handing over the completed project on
                        time what they visualised. We are a team of
                        professional, energetic individuals with talented
                        designers and experienced managers based in Australia,
                        available to guide our clients through the flawless and
                        timely execution of any residential design project.
                        Since day one, we have been delivering creative and cozy
                        designs to our clients. We do not just stop once the
                        keys are handed over rather we also offer warranties and
                        post handover support.
                      </p>
                    </div>
                    <div className="flex flex-col relative p-5">
                      <h3 className="font-semibold  text-4xl px-3">
                        CONTACT US
                      </h3>

                      <p className="py-4">
                        <ul class="w-full rounded-lg  mb-3">
                          <li class="mb-1">
                            <a
                              href="#"
                              class="w-fill flex p-3 pl-3 bg-gray-100 hover:bg-gray-200 rounded-lg"
                            >
                              <span className="material-icons md-18">
                                location_on
                              </span>
                              <span class="ml-2 truncate">
                                Gwandalan Rd Padstow NSW 2211, Australia
                              </span>
                            </a>
                          </li>
                          <li class="mb-1">
                            <a
                              href="#"
                              class="w-fill flex p-3 bg-gray-100 hover:bg-gray-200 rounded-lg"
                            >
                              <span className="material-icons md-18">
                                drafts
                              </span>
                              <span class="ml-2 truncate">
                                info@arkhomes.com.au
                              </span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              class="w-fill flex p-3 bg-gray-100 hover:bg-gray-200 rounded-lg"
                            >
                              <span className="material-icons md-18">call</span>
                              <span class="ml-2 truncate">0466 380 416</span>
                            </a>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}
