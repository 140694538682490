import Hinemoa1 from "../assets/imgs/Projects/Hinemoa/1.jpeg";
import Hinemoa2 from "../assets/imgs/Projects/Hinemoa/2.jpeg";
import Hinemoa3 from "../assets/imgs/Projects/Hinemoa/3.jpeg";
import Hinemoa4 from "../assets/imgs/Projects/Hinemoa/4.jpeg";
import Hinemoa5 from "../assets/imgs/Projects/Hinemoa/5.jpeg";
import Hinemoa6 from "../assets/imgs/Projects/Hinemoa/6.jpeg";
import Hinemoa7 from "../assets/imgs/Projects/Hinemoa/7.jpeg";
import Hinemoa8 from "../assets/imgs/Projects/Hinemoa/8.jpeg";
import Hinemoa9 from "../assets/imgs/Projects/Hinemoa/9.jpeg";

import Hinemoa10 from "../assets/imgs/Projects/Hinemoa/10.jpeg";
import Ely1 from "../assets/imgs/Projects/Ely/1.jpeg";
import Ely2 from "../assets/imgs/Projects/Ely/2.jpeg";
import Ely3 from "../assets/imgs/Projects/Ely/3.jpeg";
import Ely4 from "../assets/imgs/Projects/Ely/4.jpeg";
import Ely5 from "../assets/imgs/Projects/Ely/5.jpeg";
import Ely6 from "../assets/imgs/Projects/Ely/6.jpeg";
import Ely7 from "../assets/imgs/Projects/Ely/7.jpeg";
import Ely8 from "../assets/imgs/Projects/Ely/8.jpeg";
import Ely9 from "../assets/imgs/Projects/Ely/9.jpeg";

import Ely10 from "../assets/imgs/Projects/Ely/10.jpeg";
import Dove1 from "../assets/imgs/Projects/Dove/1.jpeg";
import Dove2 from "../assets/imgs/Projects/Dove/2.jpeg";
import Dove3 from "../assets/imgs/Projects/Dove/3.jpeg";
import Dove4 from "../assets/imgs/Projects/Dove/4.jpeg";
import Dove5 from "../assets/imgs/Projects/Dove/5.jpeg";
import Dove6 from "../assets/imgs/Projects/Dove/6.jpeg";
import Dove7 from "../assets/imgs/Projects/Dove/7.jpeg";
import Dove8 from "../assets/imgs/Projects/Dove/8.jpeg";
import Dove9 from "../assets/imgs/Projects/Dove/9.jpeg";

import Dove10 from "../assets/imgs/Projects/Dove/10.jpeg";
import Dove11 from "../assets/imgs/Projects/Dove/11.jpeg";
import Dove12 from "../assets/imgs/Projects/Dove/12.jpeg";
import Thomas1 from "../assets/imgs/Projects/Thomas/1.jpeg";
import Thomas2 from "../assets/imgs/Projects/Thomas/2.jpeg";
import Thomas3 from "../assets/imgs/Projects/Thomas/3.jpeg";
import Thomas4 from "../assets/imgs/Projects/Thomas/4.jpeg";
import Thomas5 from "../assets/imgs/Projects/Thomas/5.jpeg";
import Thomas6 from "../assets/imgs/Projects/Thomas/6.jpeg";
import Thomas7 from "../assets/imgs/Projects/Thomas/7.jpeg";
import Thomas8 from "../assets/imgs/Projects/Thomas/8.jpeg";
import Thomas9 from "../assets/imgs/Projects/Thomas/9.jpeg";

import Thomas10 from "../assets/imgs/Projects/Thomas/10.jpeg";
import Thomas11 from "../assets/imgs/Projects/Thomas/11.jpeg";
import Thomas12 from "../assets/imgs/Projects/Thomas/12.jpeg";
import Thomas13 from "../assets/imgs/Projects/Thomas/13.jpeg";
import Thomas14 from "../assets/imgs/Projects/Thomas/14.jpeg";
import Thomas15 from "../assets/imgs/Projects/Thomas/15.jpeg";

export const projects = [
  {
    id: 0.1,
    name: "Hinemoa",
    mainImg: Hinemoa7,
    altText: "Hinemoa",
    images: [
      {
        id: 1,
        url: Hinemoa7,
        altText: "Hinemoa-Front View",
      },
      {
        id: 2,
        url: Hinemoa2,
        altText: "Hinemoa-Bathroom Design",
      },
      {
        id: 3,
        url: Hinemoa3,
        altText: "Hinemoa-Bedroom Design",
      },
      {
        id: 4,
        url: Hinemoa4,
        altText: "Hinemoa-Back View from Sitting Room ",
      },
      {
        id: 5,
        url: Hinemoa5,
        altText: "Hinemoa-Bedroom Design ",
      },
      {
        id: 6,
        url: Hinemoa6,
        altText: "Hinemoa-Counter Top ",
      },
      {
        id: 7,
        url: Hinemoa1,
        altText: "Hinemoa-TV Launge ",
      },
      {
        id: 8,
        url: Hinemoa8,
        altText: "Hinemoa-TV Launge ",
      },
      {
        id: 9,
        url: Hinemoa9,
        altText: "Hinemoa-Bedroom Design ",
      },
      {
        id: 10,
        url: Hinemoa10,
        altText: "Hinemoa-Backside Gallery ",
      },
    ],
  },
  {
    id: 0.2,
    name: "Dove",
    mainImg: Dove1,
    altText: "Dove",
    images: [
      {
        id: 1,
        url: Dove1,
        altText: "Dove-Front View",
      },
      {
        id: 2,
        url: Dove2,
        altText: "Dove-Backyard View",
      },
      {
        id: 3,
        url: Dove3,
        altText: "Dove-Backside View",
      },
      {
        id: 4,
        url: Dove4,
        altText: "Dove-TV launch Design ",
      },
      {
        id: 5,
        url: Dove5,
        altText: "Dove-Outside  ",
      },
      {
        id: 6,
        url: Dove6,
        altText: "Dove-Kitchen Design ",
      },
      {
        id: 7,
        url: Dove7,
        altText: "Dove-Sitting Room ",
      },
      {
        id: 8,
        url: Dove8,
        altText: "Dove-Kitchen Design ",
      },
      {
        id: 9,
        url: Dove9,
        altText: "Dove-Washroom Design ",
      },
      {
        id: 10,
        url: Dove10,
        altText: "Dove-Sitting Area ",
      },
      {
        id: 11,
        url: Dove11,
        altText: "Dove-Bedroom Design ",
      },
      {
        id: 12,
        url: Dove12,
        altText: "Dove-Bathroom Design",
      },
    ],
  },

  {
    id: 0.3,
    name: "Ely",
    mainImg: Ely4,
    altText: "Ely",
    images: [
      {
        id: 1,
        url: Ely4,
        altText: "Ely-Front View",
      },
      {
        id: 2,
        url: Ely2,
        altText: "Ely-Kitchen Design",
      },
      {
        id: 3,
        url: Ely3,
        altText: "Ely-Bedroom Design",
      },
      {
        id: 4,
        url: Ely1,
        altText: "Ely-Kitchen Counter Top ",
      },
      {
        id: 5,
        url: Ely5,
        altText: "Ely-Sitting Area Design",
      },
      {
        id: 6,
        url: Ely6,
        altText: "Ely-Backside Gallery ",
      },
      {
        id: 7,
        url: Ely7,
        altText: "Ely-Side Sitting Area ",
      },
      {
        id: 8,
        url: Ely8,
        altText: "Ely-BackSide View ",
      },
      {
        id: 9,
        url: Ely9,
        altText: "Ely-Indoor Gallery ",
      },
      {
        id: 10,
        url: Ely10,
        altText: "Ely-Bathroom Design ",
      },
    ],
  },

  {
    id: 0.4,
    name: "Thomas",
    mainImg: Thomas1,
    altText: "Thomas",
    images: [
      {
        id: 1,
        url: Thomas1,
        altText: "Thomas-Front View",
      },
      {
        id: 2,
        url: Thomas2,
        altText: "Thomas-TV launch Design",
      },
      {
        id: 3,
        url: Thomas3,
        altText: "Thomas-Bedroom Design",
      },
      {
        id: 4,
        url: Thomas4,
        altText: "Thomas-Bedroom Design Closer View ",
      },
      {
        id: 5,
        url: Thomas5,
        altText: "Thomas-TV launch Design ",
      },
      {
        id: 6,
        url: Thomas6,
        altText: "Thomas-Bedroom Design ",
      },
      {
        id: 7,
        url: Thomas7,
        altText: "Thomas-Outside View of Gallery ",
      },
      {
        id: 8,
        url: Thomas8,
        altText: "Thomas-Bathroom Design ",
      },
      {
        id: 9,
        url: Thomas9,
        altText: "Thomas-Inner Design with TV Lanuch ",
      },
      {
        id: 10,
        url: Thomas10,
        altText: "Thomas-Inner Decorations, shelves and sitting area. ",
      },
      {
        id: 11,
        url: Thomas11,
        altText: "Thomas-Kitchen Counter Top Design. ",
      },
      {
        id: 12,
        url: Thomas12,
        altText: "Thomas-Stairs and Decor. ",
      },
      {
        id: 13,
        url: Thomas13,
        altText: "Thomas-TV Lanuch. ",
      },
      {
        id: 14,
        url: Thomas14,
        altText: "Thomas-Study Area Design and Decor. ",
      },
      {
        id: 15,
        url: Thomas15,
        altText: "Thomas-Bedroom with Sliding Window Design. ",
      },
    ],
  },
];
